<template>
  <span>
    <span @click="print">
      <slot></slot>
    </span>
    <div style="display: none">
      <div id="printJS-form">
        <template>
          <table :border="tableBorder" width="100%">
            <tbody>
              <tr>
                <td width="50%" valign="top">
                  <div>
                    <img
                      src="@/assets/images/yakyai_logo.png"
                      alt=""
                      width="auto"
                      height="100"
                    />
                  </div>
                  <p></p>
                  <div>บริษัท ยักษ์ใหญ่ ซอฟต์ จำกัด 0215563005851</div>
                  <div>351,351/1 สุขุมวิท เนินพระ เมือง ระยอง 21000</div>
                  <div>เปิด 09:00 - 17.00</div>

                  <p></p>
                  <div class="primary-color">ลูกค้า</div>
                  <div v-if="form.name_for_receipt">
                    {{ form.name_for_receipt }}
                    &nbsp;
                    {{ form.customer_taxpayer_number }}
                  </div>
                  <div v-else>
                    {{ form.customer_name }}
                    &nbsp;
                    {{ form.customer_taxpayer_number }}
                  </div>
                  <div>
                    {{ form.customer_address.address }}
                    <template v-if="form.customer_address.sub_district != ''">
                      {{ form.customer_address.sub_district }}</template
                    >
                    <template v-if="form.customer_address.district != ''">
                      {{ form.customer_address.district }}</template
                    >
                    <template v-if="form.customer_address.province != ''">
                      {{ form.customer_address.province }}</template
                    >
                    {{ form.customer_address.postal_code }}
                  </div>

                  <div>
                    <template v-if="form.customer_company_branch != ''"
                      >สาขา {{ form.customer_company_branch }}</template
                    >
                  </div>
                </td>
                <td width="50%" valign="top" align="center">
                  <div class="primary-color text-header">
                    {{ documentName }}
                  </div>
                  <br />
                  <div class="primary-color">สำเนา</div>
                  <div class="line"></div>
                  <table :border="tableBorder" width="100%">
                    <tbody>
                      <tr>
                        <td width="45%" class="primary-color">เลขที่</td>
                        <td width="40%">
                          {{ form.number }}
                        </td>
                        <td></td>
                      </tr>
                      <tr v-if="docTypeId != 0">
                        <td class="primary-color">เลขที่อ้างอิง</td>
                        <td>
                          {{ form.number }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="primary-color">วันที่</td>
                        <td>
                          {{ formatDate(form.date_of_issue) }}
                        </td>
                        <td>
                          {{ form.date_of_issue | moment($formatTime) }} น.
                        </td>
                      </tr>

                      <tr>
                        <td class="primary-color">ชื่อพนักงาน</td>
                        <td>
                          {{ form.approval }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div class="line"></div>
                        </td>
                        <td>
                          <div class="line"></div>
                        </td>
                        <td>
                          <div class="line"></div>
                        </td>
                      </tr>
                      <tr v-if="form.customer_phone_number != ''">
                        <td width="45%" class="primary-color">เบอร์โทร</td>
                        <td width="40%">
                          {{ form.customer_phone_number }}
                        </td>
                        <td style="float: left; display: table"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p></p>
          <table :border="tableBorder" width="100%" class="table line line-b">
            <tbody>
              <tr>
                <td class="table-td" align="center" width="5%">
                  <div>#</div>
                </td>
                <td class="table-td" align="center" width="40%">
                  <div>รายละเอียด</div>
                </td>
                <td
                  class="table-td"
                  align="right"
                  width="15%"
                  v-show="form.total_price > 0"
                >
                  <div>ราคา</div>
                </td>
                <td
                  class="table-td"
                  align="right"
                  width="15%"
                  v-show="form.total_discount > 0"
                >
                  <div>ส่วนลด</div>
                </td>
                <td
                  v-if="form.vat_price > 0"
                  class="table-td"
                  align="right"
                  width="10%"
                >
                  <div>Vat %</div>
                </td>
                <td class="table-td" align="right" width="15%">
                  <div>ราคารวม</div>
                </td>
              </tr>
              <tr
                v-for="(item, i) in form.product.length != 0
                  ? form.product
                  : form.product"
                :key="i"
              >
                <td class="table-td" align="center">
                  {{ i + 1 }}
                </td>
                <td class="table-td">
                  <div>{{ item.name }}</div>
                  <div class="detail">
                    {{ formatDate(item.start_date) }}
                    -
                    {{ formatDate(item.end_date) }}
                  </div>
                </td>
                <td
                  class="table-td"
                  align="right"
                  v-show="form.product[i].price > 0"
                >
                  {{ item.product_price | numeral('0,0.00') }}
                </td>
                <td
                  class="table-td"
                  align="right"
                  v-if="form.total_discount != 0"
                >
                  {{ item.discount | numeral('0,0.00') }}
                </td>
                <td v-if="form.vat_price != 0" class="table-td" align="right">
                  <!-- {{ item.vat_price | numeral("0,0.00") }} -->
                  {{ item.vat ? '7%' : '0%' }}
                </td>
                <td class="table-td" align="right">
                  {{ item.price | numeral('0,0.00') }}
                </td>
              </tr>
            </tbody>
          </table>
          <table :border="tableBorder" width="100%">
            <tbody>
              <tr>
                <td style="width: 60%" valign="top">
                  <div class="text-note">หมายเหตุ</div>
                  <div class="alert">
                    {{ form.note | '-' }}
                  </div>
                  <p></p>

                  <br />
                </td>
                <td valign="top">
                  <div>
                    <table :border="tableBorder" width="100%">
                      <tbody>
                        <tr v-show="form.total_discount > 0">
                          <td align="right" width="50%">
                            <div>รวมเป็นเงิน</div>
                          </td>
                          <td align="right" width="50%">
                            <div>
                              {{ form.total_price | numeral('0,0.00') }}
                              บาท
                            </div>
                          </td>
                        </tr>
                        <tr v-show="form.total_discount > 0">
                          <td align="right">
                            <div>ส่วนลดรวม</div>
                          </td>
                          <td align="right">
                            <div>
                              {{ form.total_discount | numeral('0,0.00') }}
                              บาท
                            </div>
                          </td>
                        </tr>
                        <tr v-if="form.vat_price">
                          <td align="right">
                            <div>ภาษีมูลค่าเพิ่ม 7%</div>
                          </td>
                          <td align="right">
                            <div>
                              {{ form.vat_price | numeral('0,0.00') }}
                              บาท
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right">
                            <div class="">จำนวนเงินรวมทั้งสิ้น</div>
                          </td>
                          <td align="right">
                            <div>
                              {{ form.net_price | numeral('0,0.00') }}
                              บาท
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right">
                            <div><br /></div>
                          </td>
                          <td align="right"><div></div></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="documentName == 'ใบเสร็จรับเงิน'">
            <br />
            <div class="note">
              ชำระเงินแล้ว ทาง
              <input
                type="checkbox"
                :checked="false"
                v-if="form.payment.type != 1"
              />
              <input
                type="checkbox"
                :checked="true"
                v-if="form.payment.type == 1"
              />
              <label class="note">โอนผ่าน QR Code</label>
              <input
                type="checkbox"
                :checked="false"
                v-if="form.payment.type != 2"
              />
              <input
                type="checkbox"
                :checked="true"
                v-if="form.payment.type == 2"
              />
              <label class="note">ชำระผ่านบัตรเครดิต</label>
              <input
                type="checkbox"
                :checked="false"
                v-if="form.payment.type != 3"
              />
              <input
                type="checkbox"
                :checked="true"
                v-if="form.payment.type == 3"
              />
              <label class="note">โอนผ่านเลขบัญชี</label>
              &nbsp;
              <!-- <label class="note">
                รายละเอียด
                {{ form.payment.reference || '-' }}
              </label> -->
            </div>
            <br v-for="(br, brindex) in 2" :key="brindex" />
            <table :border="tableBorder" width="100%">
              <tbody>
                <tr>
                  <td align="center" style="width: 22%">
                    <div>.................................</div>
                    <div>{{ signatureLeft }}</div>
                  </td>
                  <td align="center" style="width: 22%">
                    <div>.................................</div>
                    <div>วันที่</div>
                  </td>

                  <td style="text-align: center"><div></div></td>

                  <td align="center" style="width: 22%">
                    <div>.................................</div>
                    <div>{{ signatureRight }}</div>
                  </td>

                  <td align="center" style="width: 22%">
                    <div>.................................</div>
                    <div>วันที่</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </div>
  </span>
</template>

<script>
import printJS from 'print-js';
//import QRCode from 'qrcode';
import * as moment from 'moment';

export default {
  props: {
    type: {
      required: true,
      type: String,
      default: 'ใบเสร็จรับเงิน', //  1 ใบเสนอราคา, 2 ใบแจ้งหนี้, 3 ใบเสร็จรับเงิน, 4 ใบเพิ่มหนี้, 5 ใบลดหนี้
    },
    data: {
      required: false,
      type: Object,
      default: null,
    },
    dataPrint: {
      required: false,
      type: Object,
      default: null,
    },

    docTypeId: {
      required: false,
      type: Number,
      default: 0,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tableBorder: 0,
      setting: '',
      temp: null,
      hasSplitBill: false,
      total_price: 0,
      summary: {
        payment_amount: [],
        remain_change: [],
      },
      form: {
        approval: '',
        approval_id: '',
        company_group_id: '',
        coupon_code: '',
        customer_address: {
          address: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: '',
        },
        customer_company_branch: '',
        customer_id: '',
        customer_name: '',
        customer_note: '',
        customer_phone_number: '',
        customer_taxpayer_number: '',
        date_of_issue: '',
        due_date: '',
        is_withholding: false,
        name_for_receipt: '',
        net_price: 0,
        note: '',
        number: '',
        payment: {
          amount: 0,
          date: '',
          type: 0,
        },
        product: [],
        status: 1,
        total_discount: 10,
        total_price: 10,
        vat_price: 0,
        withholding: 0,
        _id: '',
      },
    };
  },

  created() {
    console.log(this.dataPrint);
    this.form = { ...this.form, ...this.dataPrint };
    console.log(this.form);
  },

  methods: {
    print() {
      if (!this.disabled) {
        // var canvas1 = document.getElementById('canvas11');
        // var canvas2 = document.getElementById('canvas13');
        // let canvas3 = null;
        // let canvas4 = null;
        // if(this.data2){
        //   var canvas3 = document.getElementById('canvas21');
        //   var canvas4 = document.getElementById('canvas23');
        // }
        if (this.documentName == 'ใบเสร็จรับเงิน') {
          // QRCode.toCanvas(canvas1, this.form[0].qr_code, (error) => {});
          // QRCode.toCanvas(canvas2, this.form[0].qr_code, (error) => {});
          // if(this.data2){
          //   QRCode.toCanvas(canvas3, this.form[1].qr_code, (error) => {});
          //   QRCode.toCanvas(canvas4, this.form[1].qr_code, (error) => {});
          // }
          setTimeout(() => {
            this.createForm();
          }, 0.5);
        } else {
          this.createForm();
        }
      }
    },

    createForm() {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        // type:'pdf',
        // header: 'PrintJS - Form Element Selection',
        font_size: '11px;',
        // font_size: '11px;',
        maxWidth: '80%',
        style: `
            @page { size: A4 portrait; margin-top: 10px; margin-left: 10px; margin-right: 10px; margin-bottom: 5px; }
            .primary-color { color: ${this.primaryColor}; }
            .alert { color: #FF5252; }
            .primary-border-radius { border-radius: 5px; border: solid 1px ${this.primaryColor}; padding: 3px 10px 3px 10px;}
            .border-radius { border-radius: 5px; border: solid 1px #ccc; padding: 10px;}
            .line { border-top: solid #ccc 1px; }
            .line-b { border-bottom: solid #ccc 1px; }
            .text-header { font-size: 20px!important; }
            .text-project { font-size: 18px!important; float:left; text-align:center; }
            .table-td {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-left: none!important;
              border-right: none!important;
              
            } 
            .subtitle {
              font-size: 12px!important;
            }
            .detail { font-size: 10px!important; }
            table, th, td {
              border-collapse: collapse;
            }
            .note {
              font-size: 10px!important;
            }
             .text-note {
             color: #FF5252; font-size: 12px!important;
            }
            .text-center {text-align: center;}
            .pagebreak { page-break-before: always; }
            `,
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
  },
  computed: {
    documentName() {
      if (this.form.status == 0) return 'ใบเสนอราคา';
      else if (this.form.status == 1) return 'ใบเสร็จรับเงิน';
      return this.form.status;
    },
    primaryColor() {
      if (this.form.status == 0) return '#7225dd';
      else if (this.form.status == 1) return '#048bff';
    },
    signatureLeft() {
      if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้จ่ายเงิน';
    },
    signatureRight() {
      if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้รับเงิน';
    },
  },
};
</script>

<style lang="scss">
#printJS-form {
  position: relative;
}
</style>
