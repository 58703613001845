<template>
  <span>
    <span @click="print">
      <slot></slot>
    </span>
    <div style="display: none">
      <div id="printJS-form">
        <template v-for="(docPage, doc_page_index) in form">
          <div :key="doc_page_index">
            <template v-if="form[doc_page_index].copy_product.length">
              <table :border="tableBorder" width="100%">
                <tbody>
                  <tr>
                    <template v-for="(itemPage, index) in 3">
                      <td :width="itemPage == 2 ? '6%' : '47%'" :key="index">
                        <div
                          v-if="
                            itemPage != 2 &&
                            !(documentName == 'ใบเสนอราคา' && itemPage == 3)
                          "
                        >
                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td width="50%" valign="top">
                                  <div
                                    v-if="form[doc_page_index].logo_url != ''"
                                  >
                                    <img
                                      :src="form[doc_page_index].logo_url"
                                      alt=""
                                      width="auto"
                                      height="40"
                                    />
                                  </div>
                                  <p></p>
                                  <div>
                                    {{ form[doc_page_index].name_for_receipt }}
                                    {{ form[doc_page_index].company_branch }}
                                    {{ form[doc_page_index].taxpayer_number }}
                                  </div>
                                  <div>
                                    {{ form[doc_page_index].location.address }}
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .sub_district != ''
                                      "
                                      >ต.{{
                                        form[doc_page_index].location
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .district != ''
                                      "
                                    >
                                      อ.{{
                                        form[doc_page_index].location.district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .province != ''
                                      "
                                    >
                                      จ.{{
                                        form[doc_page_index].location.province
                                      }}</template
                                    >
                                    {{
                                      form[doc_page_index].location.postal_code
                                    }}
                                  </div>
                                  <!-- <div>
                                    <template
                                      v-if="
                                        form[doc_page_index].taxpayer_number !=
                                          ''
                                      "
                                      >เลขผู้เสียภาษี
                                      {{
                                        form[doc_page_index].taxpayer_number
                                      }}</template
                                    >
                                  </div> -->
                                  <div>
                                    เปิด {{ form[doc_page_index].open_hours }}
                                  </div>
                                  <div>
                                    โทร. {{ form[doc_page_index].phone_number }}
                                  </div>
                                  <div>
                                    <span v-if="form[doc_page_index].facebook">
                                      Facebook:
                                      {{ form[doc_page_index].facebook }}
                                    </span>
                                    <span v-if="form[doc_page_index].line_id">
                                      Line ID:
                                      {{ form[doc_page_index].line_id }}
                                    </span>   
                                  </div>
                                  <p></p>
                                  <div class="primary-color">ลูกค้า</div>
                                  <div
                                    v-if="
                                      form[doc_page_index]
                                        .customer_taxpayer_number
                                    "
                                  >
                                    {{ form[doc_page_index].customer_name }}
                                    &nbsp;
                                    {{
                                      form[doc_page_index]
                                        .customer_taxpayer_number
                                    }}
                                  </div>
                                  <div v-else>
                                    คุณ {{ form[doc_page_index].customer_name }}
                                    &nbsp;
                                    {{
                                      form[doc_page_index]
                                        .customer_taxpayer_number
                                    }}
                                  </div>
                                  <div>
                                    {{
                                      form[doc_page_index].customer_address
                                        .address
                                    }}
                                    <template
                                      v-if="
                                        form[doc_page_index].customer_address
                                          .province != 'กรุงเทพมหานคร' && (form[doc_page_index].customer_address
                                          .sub_district != '')
                                      "
                                    >
                                      ต.{{
                                        form[doc_page_index].customer_address
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-else-if="
                                        form[doc_page_index].customer_address
                                          .province === 'กรุงเทพมหานคร' && form[doc_page_index].customer_address
                                          .sub_district != ''
                                      "
                                    >
                                      แขวง{{
                                        form[doc_page_index].customer_address
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].customer_address
                                          .district != '' && form[doc_page_index].customer_address
                                          .province != 'กรุงเทพมหานคร'
                                      "
                                    >
                                      อ.{{
                                        form[doc_page_index].customer_address
                                          .district
                                      }}</template
                                    >
                                    <template
                                      v-else-if="
                                        form[doc_page_index].customer_address
                                          .district != '' && form[doc_page_index].customer_address
                                          .province === 'กรุงเทพมหานคร'
                                      "
                                    >
                                      เขต{{
                                        form[doc_page_index].customer_address
                                          .district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].customer_address
                                          .province != ''
                                      "
                                    >
                                      จ.{{
                                        form[doc_page_index].customer_address
                                          .province
                                      }}</template
                                    >
                                    {{
                                      form[doc_page_index].customer_address
                                        .postal_code
                                    }}
                                  </div>
                                  <!-- <div>
                                    <template
                                      v-if="
                                        form[doc_page_index]
                                          .customer_taxpayer_number != ''
                                      "
                                      >เลขผู้เสียภาษี
                                      {{
                                        form[doc_page_index]
                                          .customer_taxpayer_number
                                      }}</template
                                    >
                                  </div> -->
                                  <div>
                                    <template
                                      v-if="
                                        form[doc_page_index]
                                          .customer_company_branch != ''
                                      "
                                      >สาขา
                                      {{
                                        form[doc_page_index]
                                          .customer_company_branch
                                      }}</template
                                    >
                                  </div>
                                </td>
                                <td width="50%" valign="top" align="center">
                                  <div class="primary-color text-header">
                                    {{ documentName }}
                                  </div>
                                  <br />
                                  <div
                                    v-if="documentName != 'ใบเสนอราคา'"
                                    class="primary-color"
                                  >
                                    {{
                                      itemPage == 1 && doc_page_index == 0
                                        ? 'ตัวจริง'
                                        : doc_page_index == 0
                                        ? 'สำเนา'
                                        : ''
                                    }}
                                    {{
                                      (setting.print_one_page
                                        ? itemPage == 3
                                        : itemPage == 1) && doc_page_index == 1
                                        ? 'ตัวจริง'
                                        : doc_page_index == 1
                                        ? 'สำเนา'
                                        : ''
                                    }}
                                  </div>

                                  <div class="line"></div>

                                  <table :border="tableBorder" width="100%">
                                    <tbody>
                                      <tr>
                                        <td width="45%" class="primary-color">
                                          เลขที่
                                        </td>
                                        <td width="40%">
                                          {{ form[doc_page_index].number }}
                                        </td>
                                        <td></td>
                                      </tr>
                                      <tr v-if="docTypeId != 0">
                                        <td class="primary-color">
                                          เลขที่อ้างอิง
                                        </td>
                                        <td>
                                          {{
                                            form[doc_page_index]
                                              .reference_document
                                          }}
                                        </td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td class="primary-color">วันที่</td>
                                        <td>
                                          {{
                                            formatDate(
                                              form[doc_page_index].date_of_issue
                                            )
                                          }}
                                        </td>
                                        <td>
                                          {{
                                            form[doc_page_index].date_of_issue
                                              | moment($formatTime)
                                          }}น.
                                        </td>
                                      </tr>
                                      <!-- <tr>
                                        <td class="primary-color">
                                          วันครบกำหนด
                                        </td>
                                        <td>
                                          {{
                                            formatDate(
                                              form[doc_page_index].due_date
                                            )
                                          }}
                                        </td>
                                        <td></td>
                                      </tr> -->
                                      <tr>
                                        <td class="primary-color">
                                          ชื่อพนักงาน
                                        </td>
                                        <td>
                                          {{ form[doc_page_index].approval }}
                                        </td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="line"></div>
                                        </td>
                                        <td>
                                          <div class="line"></div>
                                        </td>
                                        <td>
                                          <div class="line"></div>
                                        </td>
                                      </tr>
                                      <tr
                                        v-if="
                                          setting.show_customer_phone_number
                                        "
                                      >
                                        <td width="45%" class="primary-color">
                                          เบอร์โทร
                                        </td>
                                        <td width="40%">
                                          {{
                                            permission.show_phone_number
                                            ? form[doc_page_index].customer_phone_number
                                            : form[doc_page_index].customer_phone_number.substring(0, form[doc_page_index].customer_phone_number.length-4) + 'XXXX'
                                          }}
                                        </td>
                                        <td
                                          style="float: left; display: table"
                                        ></td>
                                      </tr>
                                      <tr>
                                        <td class="primary-color">
                                          ชนิดเอกสาร
                                        </td>
                                        <td>
                                          {{
                                            form[doc_page_index]
                                              .is_original_document
                                              ? 'เล่มจริง'
                                              : 'สำเนา'
                                          }}
                                        </td>
                                        <td></td>
                                      </tr>

                                      <tr
                                        v-if="
                                          (itemPage == 3 &&
                                            doc_page_index == 0 &&
                                            form[doc_page_index]
                                              .discounted_groups_id) ||
                                          ((setting.print_one_page
                                            ? itemPage == 1
                                            : itemPage == 3) &&
                                            doc_page_index == 1 &&
                                            form[doc_page_index]
                                              .discounted_groups_id)
                                        "
                                      >
                                        <td class="primary-color">
                                          กลุ่มลูกค้า
                                        </td>
                                        <td>
                                          {{
                                            form[doc_page_index]
                                              .discounted_groups_id
                                          }}
                                        </td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td class="primary-color">
                                          วันจดทะเบียนรถ
                                        </td>
                                        <td>
                                          {{
                                            formatDate(
                                              form[doc_page_index]
                                                .registration_date
                                            )
                                          }}
                                        </td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <p></p>

                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td width="0%"><div></div></td>
                                <td>
                                  <div class="text-project primary-color mx-10">
                                    {{ form[doc_page_index].car_plate }}
                                    {{ form[doc_page_index].car_province }}
                                    {{ setting.show_project_name ? form[doc_page_index].project_name : '' }}
                                  </div>
                                </td>
                                <td width="2%"></td>
                                <td>
                                  <div class="text-project alert">
                                    {{
                                      itemPage == 3 &&
                                      doc_page_index == 0 &&
                                      form[doc_page_index].call_alert
                                        ? 'โทรแจ้งลูกค้าด้วย'
                                        : ''
                                    }}
                                    {{
                                      (
                                        setting.print_one_page
                                          ? itemPage == 3
                                          : itemPage == 1
                                      )
                                        ? ''
                                        : doc_page_index == 1 &&
                                          doc_page_index == 1 &&
                                          form[doc_page_index].call_alert
                                        ? 'โทรแจ้งลูกค้าด้วย'
                                        : ''
                                    }}
                                  </div>
                                </td>
                              </tr>
                              <br />
                            </tbody>
                          </table>
                          <table
                            :border="tableBorder"
                            width="100%"
                            class="table line line-b"
                          >
                            <tbody>
                              <tr>
                                <td class="table-td" align="center" width="5%">
                                  <div>#</div>
                                </td>
                                <td class="table-td" align="center" width="40%">
                                  <div>รายละเอียด</div>
                                </td>
                                <td
                                  class="table-td"
                                  align="right"
                                  width="15%"
                                  v-show="
                                    form[doc_page_index].total_discount > 0
                                  "
                                >
                                  <div>ราคา</div>
                                </td>
                                <td
                                  class="table-td"
                                  align="right"
                                  width="15%"
                                  v-show="
                                    form[doc_page_index].total_discount > 0
                                  "
                                >
                                  <div>ส่วนลด</div>
                                </td>
                                <td
                                  v-if="setting.vat_registration"
                                  class="table-td"
                                  align="right"
                                  width="10%"
                                >
                                  <div>Vat %</div>
                                </td>
                                <td class="table-td" align="right" width="15%">
                                  <div>ราคารวม</div>
                                </td>
                              </tr>
                              <tr
                                v-for="(item, i) in form[doc_page_index]
                                  .original_product.length != 0 && itemPage == 1
                                  ? form[doc_page_index].original_product
                                  : form[doc_page_index].copy_product"
                                :key="i"
                              >
                                <td class="table-td" align="center">
                                  {{ i + 1 }}
                                </td>
                                <td class="table-td">
                                  <div v-if="item['start_date'] !== undefined">
                                    <div
                                      v-if="
                                        setting.show_3rd_insurance_protection_date &&
                                        item['category_id'] == 'PC1'
                                      "
                                    >
                                      พ.ร.บ {{ item.name }}
                                    </div>
                                    <div v-else>{{ item.name }}</div>
                                    <template
                                      v-if="
                                        (itemPage == 1 &&
                                          doc_page_index == 0) ||
                                        ((setting.print_one_page
                                          ? itemPage == 3
                                          : itemPage == 1) &&
                                          doc_page_index == 1)
                                      "
                                    >
                                      <div
                                        class="detail"
                                        v-if="
                                          setting.show_3rd_insurance_protection_date &&
                                          item['category_id'] == 'PC1'
                                        "
                                      >
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                      <div
                                        class="detail"
                                        v-if="
                                          setting.show_insurance_protection_date &&
                                          item['category_id'] == 'PC2'
                                        "
                                      >
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div class="detail">
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                    </template>
                                  </div>
                                  <div v-else>
                                    <div>{{ item.name }}</div>
                                    <div class="detail">{{ item.detail }}</div>
                                  </div>
                                </td>
                                <td
                                  class="table-td"
                                  align="right"
                                  v-show="
                                    form[doc_page_index].total_discount > 0
                                  "
                                >
                                  {{ item.product_price | numeral('0,0.00') }}
                                </td>
                                <td
                                  class="table-td"
                                  align="right"
                                  v-show="
                                    form[doc_page_index].total_discount > 0
                                  "
                                >
                                  {{ item.discount | numeral('0,0.00') }}
                                </td>
                                <td
                                  v-if="setting.vat_registration"
                                  class="table-td"
                                  align="right"
                                >
                                  <!-- {{ item.vat_price | numeral("0,0.00") }} -->
                                  {{ item.vat ? '7%' : '0%' }}
                                </td>
                                <td class="table-td" align="right">
                                  {{ item.price | numeral('0,0.00') }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <p></p>

                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td style="width: 60%" valign="top">
                                  <div class="text-note" v-if="form[doc_page_index].note">หมายเหตุ</div>
                                  <div class="alert">
                                    {{ form[doc_page_index].note }}
                                  </div>
                                  <p></p>
                                  <div v-if="(docTypeId < 2) || (docTypeId == 2 && setting.show_qr_code_payment == 1)">
                                    <div
                                      v-if="form[doc_page_index].qr_code_payment != '' && form[doc_page_index].qr_code_payment"
                                    >
                                      <img
                                        :src="form[doc_page_index].qr_code_payment"
                                        :style="imgStyle"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="border-radius"
                                    style="width: 80%"
                                    v-if="
                                      form[doc_page_index].delivery_address
                                        .postal_code &&
                                      form[doc_page_index]
                                        .customer_is_delivery_address
                                    "
                                  >
                                    ที่อยู่จัดส่ง
                                    <div>
                                      {{
                                        form[doc_page_index].delivery_address
                                          .address
                                      }}
                                      <template
                                        v-if="
                                          form[doc_page_index].delivery_address
                                            .sub_district != ''
                                        "
                                      >
                                        ต.{{
                                          form[doc_page_index].delivery_address
                                            .sub_district
                                        }}</template
                                      >
                                      <template
                                        v-if="
                                          form[doc_page_index].delivery_address
                                            .district != ''
                                        "
                                      >
                                        อ.{{
                                          form[doc_page_index].delivery_address
                                            .district
                                        }}</template
                                      >
                                      <template
                                        v-if="
                                          form[doc_page_index].delivery_address
                                            .province != ''
                                        "
                                      >
                                        จ.{{
                                          form[doc_page_index].delivery_address
                                            .province
                                        }}</template
                                      >
                                      {{
                                        form[doc_page_index].delivery_address
                                          .postal_code
                                      }}
                                    </div>
                                  </div>
                                  <br />
                                </td>
                                <td valign="top">
                                  <div>
                                    <table :border="tableBorder" width="100%">
                                      <tbody
                                        v-if="
                                          form[doc_page_index].document_type >=
                                          3
                                        "
                                      >
                                        <tr>
                                          <td align="right" width="50%">
                                            <div>มูลค่าตามเอกสารเดิม</div>
                                          </td>
                                          <td align="right" width="50%">
                                            <div>
                                              {{
                                                form[doc_page_index]
                                                  .original_document_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="50%">
                                            <div>มูลค่าที่ถูกต้อง</div>
                                          </td>
                                          <td align="right" width="50%">
                                            <div>
                                              {{
                                                form[doc_page_index].total_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="50%">
                                            <div>ผลต่าง</div>
                                          </td>
                                          <td align="right" width="50%">
                                            <div>
                                              {{
                                                form[doc_page_index]
                                                  .differrence_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0 || form[doc_page_index].vat_price > 0
                                          "
                                        >
                                          <td align="right" width="50%">
                                            <div>รวมเป็นเงิน</div>
                                          </td>
                                          <td align="right" width="50%">
                                            <div>
                                              {{
                                                form[doc_page_index]
                                                  .differrence_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0
                                          "
                                        >
                                          <td align="right">
                                            <div>ส่วนลดรวม</div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index]
                                                  .total_discount
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right">
                                            <div>ภาษีมูลค่าเพิ่ม 7%</div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right">
                                            <div><br /></div>
                                          </td>
                                          <td align="right"><div></div></td>
                                        </tr>
                                        <tr>
                                          <td align="right">
                                            <div class="primary-color">
                                              จำนวนเงินรวมทั้งสิ้น
                                            </div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index].net_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>

                                      <!-- Quotation, Invoice, Receipt -->
                                      <tbody
                                        v-if="
                                          form[doc_page_index].document_type < 3
                                        "
                                      >
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0 || form[doc_page_index].vat_price > 0
                                          "
                                        >
                                          <td align="right" width="50%">
                                            <div>รวมเป็นเงิน</div>
                                          </td>
                                          <td align="right" width="50%">
                                            <div>
                                              {{
                                                form[doc_page_index].total_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0
                                          "
                                        >
                                          <td align="right">
                                            <div>ส่วนลดรวม</div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index]
                                                  .total_discount
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right">
                                            <div>ภาษีมูลค่าเพิ่ม 7%</div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right">
                                            <div class="">
                                              จำนวนเงินรวมทั้งสิ้น
                                            </div>
                                          </td>
                                          <td align="right">
                                            <div>
                                              {{
                                                form[doc_page_index].net_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right">
                                            <div><br /></div>
                                          </td>
                                          <td align="right"><div></div></td>
                                        </tr>
                                        <tr
                                          v-if="
                                            setting.show_payment_amount &&
                                            !(
                                              type == 'ใบเสนอราคา' ||
                                              type == 'ใบแจ้งหนี้'
                                            )
                                          "
                                        >
                                          <td align="right">
                                            <div class="primary-color">
                                              รับเงินมา
                                            </div>
                                          </td>
                                          <td align="right">
                                            <div class="primary-color">
                                              {{
                                                summary.payment_amount[
                                                  doc_page_index
                                                ] | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            setting.show_remaining_amount &&
                                            !(
                                              type == 'ใบเสนอราคา' ||
                                              type == 'ใบแจ้งหนี้'
                                            )
                                          "
                                        >
                                          <td align="right">
                                            <div class="alert">เงินทอน</div>
                                          </td>
                                          <td align="right">
                                            <div class="alert">
                                              {{
                                                summary.remain_change[
                                                  doc_page_index
                                                ] | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table
                            v-if="docTypeId != 0"
                            :border="tableBorder"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td align="center" style="width: 45%">
                                  <div
                                    v-if="
                                      (documentName == 'ใบแจ้งหนี้' ||
                                        documentName == 'ใบเสร็จรับเงิน') &&
                                      form[doc_page_index].show_pickup
                                    "
                                  >
                                    <span
                                      class="
                                        primary-color primary-border-radius
                                        subtitle
                                      "
                                    >
                                      วันที่นัดรับงาน
                                      {{
                                        formatDate(
                                          form[doc_page_index].pick_up_date
                                        )
                                      }}
                                      {{ form[doc_page_index].pick_up_time }}
                                      เป็นต้นไป
                                    </span>
                                    <br />
                                    <br />
                                    <!-- <div>
                                      <canvas :id="'canvas' + (doc_page_index+1) + '' + item"></canvas>
                                    </div>
                                    <div>
                                      สแกน QR Code เพื่อติดตามงาน
                                    </div> -->
                                  </div>
                                </td>
                                <td
                                  valign="top"
                                  style="width: 40%"
                                  v-if="
                                    form[doc_page_index].remain_invoice.length >
                                    1
                                  "
                                >
                                  <div
                                    v-if="documentName == 'ใบเสร็จรับเงิน'"
                                    class="alert text-center"
                                  >
                                    ใบแจ้งหนี้ ที่รอการชำระ
                                  </div>
                                  <div
                                    v-if="documentName == 'ใบแจ้งหนี้'"
                                    class="text-center"
                                  >
                                    ใบแจ้งหนี้ทั้งหมดของโปรเจ็คนี้
                                  </div>
                                  <table :border="tableBorder" width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="center" style="width: 10%">
                                          #
                                        </td>

                                        <td align="center" style="width: 25%">
                                          เลขที่
                                        </td>
                                        <td align="center" style="width: 25%">
                                          ชำระก่อน
                                        </td>

                                        <td align="center" style="width: 20%">
                                          ราคารวม
                                        </td>

                                        <td align="center" style="width: 20%">
                                          สถานะ
                                        </td>
                                      </tr>
                                      <tr
                                        v-for="(invoice, invoice_index) in form[
                                          doc_page_index
                                        ].remain_invoice"
                                        :key="invoice_index"
                                      >
                                        <td align="center" style="width: 10%">
                                          {{ invoice_index + 1 }}
                                        </td>
                                        <td align="center" style="width: 25%">
                                          {{ invoice.number }}
                                        </td>
                                        <td align="center" style="width: 25%">
                                          {{ formatDate(invoice.due_date) }}
                                        </td>
                                        <td align="center" style="width: 10%">
                                          {{
                                            invoice.net_price
                                              | numeral('0,0.00')
                                          }}
                                        </td>
                                        <td align="center" style="width: 30%">
                                          {{
                                            invoice.status == 0
                                              ? 'ค้างจ่าย'
                                              : 'จ่ายแล้ว'
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <br />
                                  <div class="alert">
                                    ยอดรวมทั้งหมด
                                    {{
                                      totalNetPrice | numeral('0,0.00')
                                    }}

                                    เหลือที่ต้องชำระ
                                    {{
                                      form[doc_page_index].remain_net_price
                                        | numeral('0,0.00')
                                    }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="documentName == 'ใบเสร็จรับเงิน'">
                            <br />
                            <div class="note">
                              ชำระแล้วทาง:
                              <input
                                type="checkbox"
                                :checked="false"
                                v-if="form[doc_page_index].payment.type != 1"
                              />
                              <input
                                type="checkbox"
                                :checked="true"
                                v-if="form[doc_page_index].payment.type == 1"
                              />
                              <label class="note">เงินสด</label>
                              <input
                                type="checkbox"
                                :checked="false"
                                v-if="form[doc_page_index].payment.type != 2"
                              />
                              <input
                                type="checkbox"
                                :checked="true"
                                v-if="form[doc_page_index].payment.type == 2"
                              />
                              <label class="note">โอน</label>
                              <input
                                type="checkbox"
                                :checked="false"
                                v-if="form[doc_page_index].payment.type != 3"
                              />
                              <input
                                type="checkbox"
                                :checked="true"
                                v-if="form[doc_page_index].payment.type == 3"
                              />
                              <label class="note">บัตร</label>
                              &nbsp;
                              <label class="note">
                                รายละเอียด:
                                {{
                                  form[doc_page_index].payment.reference || '-'
                                }}
                              </label>
                              <label class="note" v-if="form[doc_page_index].payment.cashier_name!=''">
                                ผู้รับเงิน:
                                {{
                                  form[doc_page_index].payment.cashier_name || '-'
                                }}
                              </label>
                            </div>
                          </div>

                          <div
                            v-if="
                              documentName != 'ใบแจ้งหนี้' &&
                              documentName != 'ใบเสร็จรับเงิน'
                            "
                          >
                            <br v-for="(br2, br2index) in 10" :key="br2index" />
                          </div>

                          <div
                            v-if="
                              documentName == 'ใบเพิ่มหนี้' ||
                              documentName == 'ใบลดหนี้'
                            "
                          >
                            <table :border="tableBorder" width="100%">
                              <tbody>
                                <tr>
                                  <td align="left" style="width: 100%">
                                    <div>
                                      <span v-if="documentName == 'ใบเพิ่มหนี้'">
                                        ชำระแล้วทาง:
                                      </span>
                                      <span v-if="documentName == 'ใบลดหนี้'">
                                        ช่องทางการคืนเงิน
                                      </span>
                                      <input
                                        type="checkbox"
                                        :checked="false"
                                        v-if="
                                          form[doc_page_index].payment.type != 1
                                        "
                                      />
                                      <input
                                        type="checkbox"
                                        :checked="true"
                                        v-if="
                                          form[doc_page_index].payment.type == 1
                                        "
                                      />
                                      <label>เงินสด</label>
                                      <input
                                        type="checkbox"
                                        :checked="false"
                                        v-if="
                                          form[doc_page_index].payment.type != 2
                                        "
                                      />
                                      <input
                                        type="checkbox"
                                        :checked="true"
                                        v-if="
                                          form[doc_page_index].payment.type == 2
                                        "
                                      />
                                      <label>โอน</label>
                                      <input
                                        type="checkbox"
                                        :checked="false"
                                        v-if="
                                          form[doc_page_index].payment.type != 3
                                        "
                                      />
                                      <input
                                        type="checkbox"
                                        :checked="true"
                                        v-if="
                                          form[doc_page_index].payment.type == 3
                                        "
                                      />
                                      <label>บัตร</label>
                                      <label>
                                        จำนวนเงิน
                                        {{
                                          form[doc_page_index].net_price
                                            | numeral('0,0.00')
                                        }}
                                        บาท รายละเอียด:
                                        {{
                                          form[doc_page_index].payment
                                            .reference || '-'
                                        }}
                                      </label>
                                      <label v-if="form[doc_page_index].payment.cashier_name!=''">
                                        <span v-if="documentName == 'ใบเพิ่มหนี้'">
                                          ผู้รับเงิน:
                                        </span>
                                        <span v-if="documentName == 'ใบลดหนี้'">
                                          ผู้คืนเงิน:
                                        </span>
                                        {{
                                          form[doc_page_index].payment.cashier_name || '-'
                                        }}
                                      </label>
                                    </div>
                                  </td>
                                  <td align="center" style="width: 30%"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br v-for="(br, brindex) in 2" :key="brindex" />
                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="width: 22%">
                                  <div>.................................</div>
                                  <div>{{ signatureLeft }}</div>
                                </td>
                                <td align="center" style="width: 22%">
                                  <div>.................................</div>
                                  <div>วันที่</div>
                                </td>

                                <td style="text-align: center"><div></div></td>

                                <td align="center" style="width: 22%">
                                  <div>.................................</div>
                                  <div>{{ signatureRight }}</div>
                                </td>

                                <td align="center" style="width: 22%">
                                  <div>.................................</div>
                                  <div>วันที่</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
              <div
                class="pagebreak"
                v-if="doc_page_index == 0 && form[1]"
              ></div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </span>
</template>

<script>
import printJS from 'print-js';
////import QRCode from 'qrcode';
import * as moment from 'moment';

export default {
  props: {
    type: {
      required: true,
      type: String,
      default: 'ใบแจ้งหนี้', //  1 ใบเสนอราคา, 2 ใบแจ้งหนี้, 3 ใบเสร็จรับเงิน, 4 ใบเพิ่มหนี้, 5 ใบลดหนี้
    },
    data: {
      required: false,
      type: Object,
      default: null,
    },
    data2: {
      required: false,
      type: Object,
      default: null,
    },
    docTypeId: {
      required: false,
      type: Number,
      default: 0,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    firstCreated: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableBorder: 0,
      setting: '',
      permission: {},
      temp: null,
      hasSplitBill: false,
      total_price: 0,
      summary: {
        payment_amount: [],
        remain_change: [],
      },
      form: [
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
          qr_code_payment: '',
        },
        /* form 2 */
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
        },
      ],
    };
  },

  created() {
    this.getUserGroup();
    if (this.data) {
      this.form[0] = { ...this.form[0], ...this.data };
    }
    if (this.data2) {
      this.hasSplitBill = true;
      this.form[1] = { ...this.form[1], ...this.data2 };
      if (this.form[1].logo_url == '')
        this.form[1].logo_url = this.form[0].logo_url;
      this.total_price = this.form[0].net_price + this.form[1].net_price;
    } else {
      this.form.pop(this.form[1]);
    }
    this.getBranchSetting();

    this.summary.payment_amount.push(
      this.hasSplitBill ? this.form[0].net_price : this.form[0].payment.amount
    );
    this.summary.payment_amount.push(
      this.hasSplitBill
        ? this.form[1].payment.amount - this.form[0].net_price
        : 0
    );
    this.summary.remain_change.push(
      this.hasSplitBill
        ? 0
        : this.form[0].payment.amount - this.form[0].net_price
    );
    this.summary.remain_change.push(
      this.hasSplitBill ? this.form[1].payment.amount - this.total_price : 0
    );
  },

  methods: {
    async getBranchSetting() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
      });
      this.setting = listFiltered[0].setting;
        if (this.setting.print_one_page && this.data2) {
          this.temp = [...this.form[1].original_product];
          this.form[1].original_product = [...this.form[1].copy_product];
          this.form[1].copy_product = [...this.temp];
        }
        if (this.setting.show_late_tax_fine && this.firstCreated) {
          for (var i = 0; i < this.form.length; i++) {
            for (var j = 0; j < this.form[i].copy_product.length; j++) {
              if (this.form[i].copy_product[j].tag == 'tax_include_fines') {
                this.form[i].copy_product[j].name = this.form[i].copy_product[
                  j
                ].name.concat(' ', this.form[i].copy_product[j].print_detail);
                break;
              }
            }
            if (!this.setting.is_split_bill) {
              for (var j = 0; j < this.form[i].original_product.length; j++) {
                if (
                  this.form[i].original_product[j].tag == 'tax_include_fines'
                ) {
                  var price = this.form[i].original_product[j].price;
                  this.form[i].original_product[j].name = this.form[
                    i
                  ].original_product[j].name.concat(
                    ' ',
                    this.form[i].original_product[j].print_detail
                  );
                  break;
                }
              }
            }
          }
          this.firstCreated = false;
        }
    },
    print() {
      if (!this.disabled) {
        // var canvas1 = document.getElementById('canvas11');
        // var canvas2 = document.getElementById('canvas13');
        // let canvas3 = null;
        // let canvas4 = null;
        // if(this.data2){
        //   var canvas3 = document.getElementById('canvas21');
        //   var canvas4 = document.getElementById('canvas23');
        // }
        if (
          this.documentName == 'ใบแจ้งหนี้' ||
          this.documentName == 'ใบเสร็จรับเงิน'
        ) {
          // QRCode.toCanvas(canvas1, this.form[0].qr_code, (error) => {});
          // QRCode.toCanvas(canvas2, this.form[0].qr_code, (error) => {});
          // if(this.data2){
          //   QRCode.toCanvas(canvas3, this.form[1].qr_code, (error) => {});
          //   QRCode.toCanvas(canvas4, this.form[1].qr_code, (error) => {});
          // }
          setTimeout(() => {
            this.createForm();
          }, 0.5);
        } else {
          this.createForm();
        }
      }
    },

    createForm() {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        // type:'pdf',
        // header: 'PrintJS - Form Element Selection',
        font_size: '11px;',
        // font_size: '11px;',
        maxWidth: '80%',
        style: `
            @page { size: A4 landscape; margin-top: 10px; margin-left: 10px; margin-right: 10px; margin-bottom: 5px; }
            .primary-color { color: ${this.primaryColor}; }
            .alert { color: #FF5252; }
            .primary-border-radius { border-radius: 5px; border: solid 1px ${this.primaryColor}; padding: 3px 10px 3px 10px;}
            .border-radius { border-radius: 5px; border: solid 1px #ccc; padding: 10px;}
            .line { border-top: solid #ccc 1px; }
            .line-b { border-bottom: solid #ccc 1px; }
            .text-header { font-size: 20px!important; }
            .text-project { font-size: 18px!important; float:left; text-align:center; }
            .table-td {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-left: none!important;
              border-right: none!important;
              
            } 
            .subtitle {
              font-size: 12px!important;
            }
            .detail { font-size: 10px!important; }
            table, th, td {
              border-collapse: collapse;
            }
            .note {
              font-size: 10px!important;
            }
             .text-note {
             color: #FF5252; font-size: 12px!important;
            }
            .text-center {text-align: center;}
            .pagebreak { page-break-before: always; }
            `,
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        
      } catch (error) {
        console.error('Error getUserGroup in print:', error);
        Sentry.captureException(error);
        this.permission.show_phone_number = true;
      };
    },
  },
  computed: {
    totalNetPrice() {
    // Calculate the sum of invoice.net_price values
    return this.form[0].remain_invoice.reduce((total, invoice) => {
      return total + invoice.net_price;
    }, 0);
    },
    documentName() {
      if (this.type == 1) return 'ใบเสนอราคา';
      else if (this.type == 2) return 'ใบแจ้งหนี้';
      else if (this.type == 3) return 'ใบเสร็จรับเงิน';
      else if (this.type == 4) return 'ใบเพิ่มหนี้';
      else if (this.type == 5) return 'ใบลดหนี้';
      return this.type;
    },
    primaryColor() {
      if (this.type == 'ใบเสนอราคา') return '#7225dd';
      else if (this.type == 'ใบแจ้งหนี้') return '#d86f07';
      else if (this.type == 'ใบเสร็จรับเงิน') return '#048bff';
      else if (this.type == 'ใบเพิ่มหนี้') return 'red';
      else if (this.type == 'ใบลดหนี้') return '#49aa1c';
    },
    signatureLeft() {
      if (this.type == 'ใบเสนอราคา') return 'ผู้สั่งซื้อ';
      else if (this.type == 'ใบแจ้งหนี้') return 'ผู้รับแจ้งหนี้';
      else if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้จ่ายเงิน';
      else if (this.type == 'ใบเพิ่มหนี้') return 'ผู้จ่ายเงิน';
      else if (this.type == 'ใบลดหนี้') return 'ผู้จ่ายเงิน';
    },
    signatureRight() {
      if (this.type == 'ใบเสนอราคา') return 'ผู้เสนอราคา';
      else if (this.type == 'ใบแจ้งหนี้') return 'ผู้แจ้งหนี้';
      else if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้รับเงิน';
      else if (this.type == 'ใบเพิ่มหนี้') return 'ผู้เสนอราคา';
      else if (this.type == 'ใบลดหนี้') return 'ผู้เสนอราคา';
    },
    imgStyle() {
      const img = new Image();
      img.src = this.form.qr_code_payment;

      let width = '210px'; // default width
      let height = 'auto'; // default height

      img.onload = () => {
        if (img.width > img.height) {
          width = '500px';
          height = 'auto';
        } else if (!this.form.qr_code_payment) {
          height = '167px'; // set to 167px if no logo URL
        }
      };

      return { width, height };
    },
  },
};
</script>

<style lang="scss">
#printJS-form {
  position: relative;
}
</style>
